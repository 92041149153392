import React, {Component} from 'react';
import styles from "./IndexTagList.module.sass";
import IndexTagItem from "./IndexTagItem/IndexTagItem";

export default class IndexTagList extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.indexTagsItem = this.props.data.map((el) =>
            <IndexTagItem indexTag={el}/>
        );

    }

    render() {
        return (
            <div>
                {this.indexTagsItem}
            </div>
        );
    }
}