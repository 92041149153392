import React, {Component} from 'react';
import styles from "./IndexTagItem.module.sass";
import classNames from "classnames";

export default class IndexTagItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState({
            hover: !this.state.hover
        })
    }


    render() {
        return (
            <div className={styles.indexTagItem} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                <span>{this.props.indexTag.node.frontmatter.title}</span>
                {this.props.indexTag.node.frontmatter.image && <img className={classNames({[styles.active]: this.state.hover})} src={this.props.indexTag.node.frontmatter.image.publicURL} alt=""/>}
            </div>
        );
    }
}